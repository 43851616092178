export const getInviteEmailTemplate = (firstName, personInSearch, sex) => {
  return `
    <!DOCTYPE html>
<html>
<head>

  <meta charset="utf-8">
  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <title>Email Receipt</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
  /**
   * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
   */
  @media screen {
    @font-face {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
    }

    @font-face {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
    }
  }

  /**
   * Avoid browser level font resizing.
   * 1. Windows Mobile
   * 2. iOS / OSX
   */
  body,
  table,
  td,
  a {
    -ms-text-size-adjust: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  /**
   * Remove extra space added to tables and cells in Outlook.
   */
  table,
  td {
    mso-table-rspace: 0pt;
    mso-table-lspace: 0pt;
  }

  /**
   * Better fluid images in Internet Explorer.
   */
  img {
    -ms-interpolation-mode: bicubic;
  }

  /**
   * Remove blue links for iOS devices.
   */
  a[x-apple-data-detectors] {
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
    text-decoration: none !important;
  }

  /**
   * Fix centering issues in Android 4.4.
   */
  div[style*="margin: 16px 0;"] {
    margin: 0 !important;
  }

  body {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  /**
   * Collapse table borders to avoid space between cells.
   */
  table {
    border-collapse: collapse !important;
  }

  a {
    color: #1a82e2;
  }

  img {
    height: auto;
    line-height: 100%;
    text-decoration: none;
    border: 0;
    outline: none;
  }
  </style>

</head>
<body style="background-color: #808080;padding-top: 30px!important;padding-bottom: 30px!important">

  <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
    School images are now available!
  </div>
  <!-- end preheader -->
  <table border="0" cellpadding="0" cellspacing="0" width="100%">
   
    <tr>
      <td align="center" bgcolor="#808080">

        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
          <tr>
            <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
              <p style="margin: 0;">Dear ${firstName},</p>
              <br>  
              <p style="margin: 0;">You have previously asked how you can have access to ${personInSearch}'s photos taken while ${
    sex === "M" ? "he" : "she"
  } was at school, so I am just letting you know that we have built a website specifically for this, see link below:</p>
              <br>
              <p style="margin: 0;"><a href="https://oldschoolphotos.co.za">https://oldschoolphotos.co.za</a></p>
              <br>
              <p style="margin: 0;">The site is only accessible by past students or parents so you will need to register and link yourself to ${personInSearch} before you can search for ${
    sex === "M" ? "his" : "her"
  } photos. You will also need to upload a clear photo of ${personInSearch} to run the search.</p>
              <br>
              <p style="margin: 0;">You will be able to buy and download any or all of them.</p>
              <br>
              <p style="margin: 0;">Enjoy, I hope you like them!</p>
              <br>
              <p style="margin: 0;">Regards,</p>
              <p style="margin: 0;">Ant Smyth</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

</body>
</html>
    `;
};

import React from "react";
import Close from "@material-ui/icons/Close";
import styles from "./ReferAFriendModal.module.css";
import Button from "../button/Button";

const ReferAFriendModal = ({ closeModal }) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalCard}>
        <span className={styles.closeModal} onClick={() => closeModal()}>
          <Close style={{ fontSize: "30px" }} />
        </span>

        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Tell a Friend</h2>
        </div>

        <p>If you like this site please share it with your friends!</p>
        <p>
          That would really help us continue to provide this service. Either
          copy the web address (URL) and send it to your friends or click the
          button below. That will open an email with the link in it. Just add
          your friend's email address and send!
        </p>

        <a
          href={`mailto:?subject=Download your school photos&body=Visit https://oldschoolphotos.co.za to download your/your child's school photos`}
        >
          <Button
            isRedirect={false}
            isPrimary={true}
            buttonText='Send an email'
            disabled={false}
          />
        </a>
      </div>
    </div>
  );
};

export default ReferAFriendModal;
